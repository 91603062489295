<template>
  <div class="recharge">
    <img :src="rechargeImg.content" alt="">    
    <div>{{ langCommon.coming }}</div>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  computed: {
    ...Vuex.mapGetters([
      "rechargeImg",
      "langCommon"
    ]),
  },

}
</script>
<style lang="stylus" scoped>
.recharge {
  text-align: center;
  & > img{
    width: 30%;  
    max-width: 2.8rem;
    margin: 20vh auto .24rem;
  }
  & div {
    text-align center;
    font-size: $Ftm1;
    color: $C0;
    margin-bottom: .6rem;
  }
}
@media screen and (max-width: 980px){
  .recharge {
    & > img{
      width: 40%;  
      max-width: 40%;
    }
    & div {
      font-size: $Fts3;
    }
  }
}

</style>